import React from "react"
import ConsentCheckbox from '../../../components/ConsentCheckbox';

const PageThree = ({ show, dataFromlocalStorage, setValue }) => {
  const pageDisplayStyle = show
    ? {
        display: "block",
      }
    : {
        display: "none",
      }
  return (
    <div className="page-three page" id="page-three" style={pageDisplayStyle}>
      <div>
        <div className="field name-field">
          <label htmlFor="who-are-emails-from-name">
            Who would you like emails to come from? (ex. Employee’s name or
            Company Name)
          </label>
          <input
            type="text"
            name="who-are-emails-from-name"
            id="who-are-emails-from-name"
            onChange={e => setValue("who-are-emails-from-name", e.target.value)}
            defaultValue={
              dataFromlocalStorage
                ? dataFromlocalStorage["who-are-emails-from-name"]
                : ""
            }
            required
          ></input>
        </div>
        <div className="field email-field">
          <label htmlFor="who-are-emails-from-email">
            What email address would you like emails to come from? (ex. Steve@
            or Company@)
          </label>
          <input
            type="text"
            name="who-are-emails-from-email"
            id="who-are-emails-from-email"
            onChange={e =>
              setValue("who-are-emails-from-email", e.target.value)
            }
            defaultValue={
              dataFromlocalStorage
                ? dataFromlocalStorage["who-are-emails-from-email"]
                : ""
            }
            required
          ></input>
        </div>
        <div className="field email-field">
          <label htmlFor="form-submission-email">
            What email would you like form submission to go to? (ex. Sales@ or
            Steve@)
          </label>
          <input
            type="text"
            name="form-submission-email"
            id="form-submission-email"
            onChange={e => setValue("form-submission-email", e.target.value)}
            defaultValue={
              dataFromlocalStorage
                ? dataFromlocalStorage["form-submission-email"]
                : ""
            }
            required
          ></input>
        </div>
      </div>
      <div style={{ marginTop: 20 }}>
        <ConsentCheckbox />
      </div>
    </div>
  )
}

export default PageThree
